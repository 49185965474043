import React from 'react'

import { Subheading, Para, Article } from '../../components/article'
import { Link } from '@reach/router'

export default () => (
  <Article title="On-premise monitoring agents">
    <Subheading>Do I need an on-premise agent?</Subheading>
    <Para>
      In short, if you'ure using Whoops.io to monitor websites or servers which are reachable
        publicly from the internet, then no, you do not need on-premise agents.
    </Para>
    <Para>
        The primary purpose of on-premise agents is to allow you to monitor servers which are
        inside a private network you control, which are <em>not</em> exposed to the public internet
    </Para>
    <Para>
        Read our <Link to="/article/onprem-agents-private-beta">on-premise agents
        announcement</Link> for more information.
    </Para>
    <Subheading>Usage</Subheading>
    <ol className="pl-4">
      <li className="mb-4">
        <strong>Install Docker</strong>:
        <p>
            Our agent ships as a docker image, so if you aren't familiar with Docker already,
            you'll need a server somewhere on your network that has <a href="https://www.docker.com/">
            Docker</a> installed and running.
        </p>
      </li>
      <li className="mb-4">
        <strong>Get your API credentials</strong>:
        <p>
            Log in to your <a href="https://app.whoops.io">Whoops account</a>, select the workspace you
            want to monitor using your on-prem agent, and go to Settings. Scroll to the bottom of the
            page and make note of your <em>Workspace ID</em>, <em>User ID</em>, and <em>API Token</em>.
        </p>
      </li>
      <li className="mb-4">
        <strong>Run the agent</strong>:
        <p>This is a simple <code className="bg-grey-light text-sm p-1">docker run</code> command, passing
            your credentials/settings as environment variables.
        </p>
        <p>
            You'll need to set each of the following environment variables:
        </p>
        <ul className="mb-4">
          <li><code className="bg-grey-light text-sm py-1 px-2">WHOOPS_PLATFORM_BASE_URI</code> Always set to <code>
              https://api.whoops.io</code></li>
          <li><code className="bg-grey-light text-sm py-1 px-2">WHOOPS_USER_ID</code> Your User ID</li>
          <li><code className="bg-grey-light text-sm py-1 px-2">WHOOPS_PLATFORM_AUTH_TOKEN</code> Your API Token</li>
          <li><code className="bg-grey-light text-sm py-1 px-2">WHOOPS_WORKSPACE_ID</code> Your Workspace ID <span className="text-blue">*</span></li>
          <li><code className="bg-grey-light text-sm py-1 px-2">WHOOPS_AGENT_NAME</code> A custom name you define (
              e.g. <code>my-onprem-agent</code>)</li>
          <li><code className="bg-grey-light text-sm py-1 px-2">WHOOPS_ALLOW_PRIVATE_SUBNETS</code> Set to <code>"true"</code> <span className="text-blue">**</span></li>
        </ul>
        <p>Here's a full example:</p>
        <p className="bg-grey-light text-sm font-mono p-4 my-4">
            docker run \
          <br/>&nbsp;&nbsp; -e WHOOPS_PLATFORM_BASE_URI=https://api.whoops.io \
          <br/>&nbsp;&nbsp; -e WHOOPS_USER_ID=my-user-id \
          <br/>&nbsp;&nbsp; -e WHOOPS_PLATFORM_AUTH_TOKEN=my-api-token \
          <br/>&nbsp;&nbsp; -e WHOOPS_WORKSPACE_ID=my-workspace-id \
          <br/>&nbsp;&nbsp; -e WHOOPS_AGENT_NAME=my-onprem-agent \
          <br/>&nbsp;&nbsp; -e WHOOPS_ALLOW_PRIVATE_SUBNETS=true \
          <br/>&nbsp;&nbsp; --network=host \
          <br/>&nbsp;&nbsp; whoopsio/agent
        </p>
        <p>
            <span className="text-blue">*</span>If you do not set a <code>WHOOPS_WORKSPACE_ID</code> then the on-prem agent will run uptime
            checks for checks across all workspaces you have access to. So if you have multiple workspaces, and
            want to run uptime checks on them all from the same on-prem agent, then do not set this env var.
        </p>
        <p>
            <span className="text-blue">**</span>If you do not set <code>WHOOPS_ALLOW_PRIVATE_SUBNETS</code> to true, then the on-prem agent will
            skip any checks it finds within any reserved IP address ranges.
        </p>
      </li>
      <li className="mb-4">
        <strong>Setup your checks</strong>:
        <p>
            Log in to the Whoops.io platform and configure your checks as normal. Our normal cloud agents
            will automatically recognise any checks which target internal/private addresses; our cloud
            agents will skip them, but your on-premise agent(s) will execute the check.
        </p>
      </li>
    </ol>
    <Subheading>Running multiple on-prem agents</Subheading>
    <Para>
        You can run multiple on-prem agents at once for the same account/worksapace. Just make sure each agent has
        a unique <code>WHOOPS_AGENT_NAME</code> set.
    </Para>
    <Subheading>Troubleshooting</Subheading>
    <Para>
        If you have any questions or problems setting up your on-prem agent, feel free to get in touch with us
        via <Link to="/support">our support page</Link>.
    </Para>
  </Article>
)
